// DICOM utilities
// ---------------

// Dependencies
import Vue from "vue";
import { Observable } from "rxjs";
import { schemaToStackMap } from "@/js/preferences";
import { postStudy, sendDicomFiles } from "@/js/api.schema";

// Private methods

// const randomString = n => {
//   var p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   return [...Array(n)].reduce(a => a + p[~~(Math.random() * p.length)], "");
// };

// Extract schema data (grouped by studies) from stacks and convert to upload object
const stacksToUploadFormat = stacks => {
  let result = stacksToSchemaFormat(stacks);

  // Remove metadata ids (will be assigned by the server)
  // Convert metadata pixel spacing from array to single items
  result = result.map(study => {
    delete study.id;
    delete study.patient.id;
    study.series.forEach(s => {
      s.pixel_spacing_x = s.pixel_spacing ? s.pixel_spacing[0] : null;
      s.pixel_spacing_y = s.pixel_spacing ? s.pixel_spacing[1] : null;
      delete s.pixel_spacing;
      delete s.id;
    });
    return study;
  });

  // convert iso date time strings to date strings
  result.forEach(d => {
    d.study_date = d.study_date ? d.study_date.substring(0, 10) : d.study_date;
    d.patient.birth_date = d.patient.birth_date
      ? d.patient.birth_date.substring(0, 10)
      : d.patient.birth_date;
  });

  return result;
};

// Upload all series files of a study, using queues
const uploadSeriesInStudy = study => {
  const uploadQueueIds = study.series.map(s => [
    s.id,
    s.series_id,
    s.number_of_slices
  ]);

  const nextSeries = async sub => {
    if (!uploadQueueIds.length) {
      sub.complete();
      return;
    }

    const [dbSeriesId, seriesId, numberOfInstances] = uploadQueueIds.shift();
    console.warn("START UPLOAD FOR SERIES", dbSeriesId);
    console.time(`Series ${dbSeriesId} upload`);
    sub.next({ start: true, seriesId, numberOfInstances });
    let stack = Vue.prototype.$ditto.dicom.getSeriesStack(seriesId);
    // try to anonymize here
    if (stack.anonymized) {
      // anonymize studydata
      const patientName = study.patient.name;
      const patientBirthdate = study.patient.birth_date;
      const patientGender = study.patient.gender;
      const tagTonAnonymize = {
        x00100010: patientName,
        x00100030: patientBirthdate, // Patient's Birth Date
        // "x00100032": '00:00:00', // Patient's Birth Time
        x00100040: patientGender, // Patient's Sex
        x00101000: study.patient.patient_id, // Other Patient Ids
        x00101001: "", // Other Patient Names
        x00101010: 0, // Patient's Age
        x00101020: "", // Patient's Size
        x00101030: "", // Patient's Weight
        x00080050: study.accession_number, // Accession Number
        x00080080: "", // Institution Name
        x00080081: "", // Institution Address
        x00080090: "", // Referring Physician's Name
        x00080092: "", // Referring Physician's Address
        x00080094: "", // Referring Physician's Telephone numbers
        x00081010: "", // Station Name
        x00081030: study.study_description, // Study Description
        x0008103e: "", // Series Description
        x00081040: "", // Institutional Department name
        x00081048: "", // Physician(s) of Record
        x00081050: "", // Performing Physicians' Name
        x00081060: "", // Name of Physician(s) Reading study
        x00081070: "" // Operator's Name
        /* OTHER TAGS TO ANONYMIZE


        "x00102160", // Ethnic Group
        "x00102180", // Occupation
        "x001021b0", // Additional Patient's History
        "x00104000", // Patient Comments
        "x00181030", // Protocol Name
        "x00200010", // Study ID
        "x00204000", // Image Comments
        */
      };
      const anonymizedStack = Vue.prototype.$ditto.dicom.anonymizeSeriesStack(
        stack,
        tagTonAnonymize
      );
      // repack the files with new anonymized data
      for (const key of Object.keys(anonymizedStack.instances)) {
        const singleInstance = anonymizedStack.instances[key];
        // create new file for a single instance
        const anonByteArray = singleInstance.dataSet.byteArray;
        const anonBlob = new Blob([new Uint8Array(anonByteArray).buffer], {
          type: "application/dcm"
        });
        const anonDCMFile = new File([anonBlob], singleInstance.file.name, {
          type: singleInstance.file.type,
          lastModified: new Date()
        });
        singleInstance.file = anonDCMFile;
        anonymizedStack.instances[key] = singleInstance;
      }
      anonymizedStack["x00100010"] =
        anonymizedStack.instances[
          Object.keys(anonymizedStack.instances)[0]
        ].metadata.patientName;
      stack = anonymizedStack;
    }
    // IF ANONYMIZE we need to WRITE new FILES

    const sendSubscription = sendDicomFiles(dbSeriesId, stack)
      .subscribe({
        // emit data to study observer
        next: data => sub.next({ seriesId, ...data }),
        error: error => sub.error({ seriesId, error })
      })
      .add(() => {
        console.warn("END UPLOAD FOR SERIES", dbSeriesId);
        console.timeEnd(`Series ${dbSeriesId} upload`);
        sub.next({ seriesId, end: true });
        sendSubscription?.unsubscribe();
        nextSeries(sub);
      });
  };

  return new Observable(subscriber => nextSeries(subscriber));
};

// Public methods

export const getTypedArray = data => {
  let strRepr = data.repr == 0 ? "Uint" : "Int";
  let strBits = data.bits.toString();

  switch (strRepr + strBits) {
    case "Uint8":
      return new Uint8Array(data.rows * data.cols * data.number_of_slices);
    case "Int8":
      return new Int8Array(data.rows * data.cols * data.number_of_slices);
    case "Uint16":
      return new Uint16Array(data.rows * data.cols * data.number_of_slices);
    case "Int16":
      return new Int16Array(data.rows * data.cols * data.number_of_slices);
    default:
  }
};

// Extract schema data (grouped by studies) from stacks
export const stacksToSchemaFormat = stacks => {
  const convertGroup = (group, stack) => {
    return Object.keys(schemaToStackMap[group]).reduce(
      (result, schemaKey) => ({
        ...result,
        [schemaKey]: stack[schemaToStackMap[group][schemaKey]]
      }),
      {}
    );
  };

  return stacks.reduce((allStacks, stack) => {
    const series = convertGroup("series", stack);
    let rStudy = allStacks.find(
      ({ id }) => id == stack[schemaToStackMap.study.id]
    );
    if (rStudy) {
      rStudy.series.push(series);
    } else {
      let patient = convertGroup("patient", stack);
      //patient = splitPatientName(patient);

      allStacks.push({
        ...convertGroup("study", stack),
        patient: patient,
        series: [series]
      });
    }
    return allStacks;
  }, []);
};

// Upload dicom data using queues
export const uploadStudies = (data, uploadId) => {
  const uploadQueue = stacksToUploadFormat(data);

  const nextStudy = async sub => {
    if (!uploadQueue.length) {
      sub.complete();
      return;
    }

    // Await for post study, then send all series files
    const study = uploadQueue.shift();
    let savedStudy;
    console.warn("START UPLOAD FOR STUDY", study.study_id);
    try {
      // check if series in study must be anonymized
      let studyToUpload = study;
      if (study.series[0] && study.series[0].anonymized) {
        // anonymize patient data
        studyToUpload.patient.name = Vue.prototype.$ditto.dicom.hashValue(
          studyToUpload.patient.name
        );
        studyToUpload.patient.birth_date = "1900-01-01";
        studyToUpload.patient.gender = "";
        // anonymize studydata
        studyToUpload.study_date = "1900-01-01";
        studyToUpload.study_description = Vue.prototype.$ditto.dicom.hashValue(
          studyToUpload.study_description
        );
        // anonymize series data
        studyToUpload.series = study.series.map(series => {
          const anonSeries = series;
          anonSeries.series_description = Vue.prototype.$ditto.dicom.hashValue(
            series.series_description
          );
          anonSeries.study_date = studyToUpload.study_date;
          return anonSeries;
        });
      }
      savedStudy = await postStudy(studyToUpload, uploadId);
      savedStudy.patient = studyToUpload.patient;
      sub.next({
        startUpload: true
      });
      savedStudy.series = savedStudy.series.map(s => ({
        ...s,
        number_of_slices: study.series.find(r => r.series_id == s.series_id)
          .seriesInstanceUIDs.length
      }));

      // some series in study can have errors (files will not be sent)
      if (savedStudy.errors) {
        savedStudy.errors.forEach(e => {
          // omit keys user does not need to see
          // eslint-disable-next-line no-unused-vars
          var { series_id, serie_index, ...e2 } = e;
          sub.next({
            error: e2,
            seriesId: study.series[e.serie_index]?.series_id
          });
        });
      }
    } catch (error) {
      // post fails, all series in study have an upload error
      const seriesIds = study.series.map(s => s.series_id);
      seriesIds.forEach(id =>
        sub.next({
          error: typeof error.body == "object" ? error.body : error,
          seriesId: id
        })
      );

      // try with next study
      nextStudy(sub);
      return;
    }
    // Send series files
    const uploadSeriesSubscription = uploadSeriesInStudy(savedStudy)
      .subscribe({
        // emit data to main observer
        next: data => sub.next(data),
        error: error => sub.error(error)
      })
      .add(() => {
        console.warn("END UPLOAD FOR STUDY", savedStudy.id);
        sub.next({
          uploadedStudy: data
        });
        uploadSeriesSubscription?.unsubscribe();
        nextStudy(sub);
      });
  };

  return new Observable(subscriber => nextStudy(subscriber));
};
